import React, { useEffect, useState } from 'react';
import CircleAvatars from '../components/CircleAvatars';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './UserProfile.module.css';

const baseUrl = process.env.REACT_APP_API_URL;

export default function UserProfile() {
    const [user, setUser] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const [editBio, setEditBio] = useState('');
    const [editHeadline, setEditHeadline] = useState('');
    const [editUsername, setEditUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [newLinkTitle, setNewLinkTitle] = useState('');
    const [newLinkUrl, setNewLinkUrl] = useState('');
    const [socials, setSocials] = useState({
        facebook: '',
        x: '',
        instagram: '',
        linkedin: '',
        github: ''
    });

    const navigate = useNavigate();
    const { username } = useParams();

    useEffect(() => {
        if (!username) return;
        const withAt = username.startsWith("@") ? username : `@${username}`;
        fetch(`${baseUrl}/api/users/${withAt}`)
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    setErrorMessage(data.error);
                } else {
                    setUser(data);
                    setEditName(data.name);
                    setEditBio(data.bio);
                    setEditHeadline(data.headline || '');
                    setEditUsername(data.username);
                    setSocials(data.socials || {});
                }
            })
            .catch(() => setErrorMessage("Failed to fetch user data."));
    }, [username]);

    function handleAddLink() {
        if (!newLinkTitle.trim() || !newLinkUrl.trim()) return;
        fetch(`${baseUrl}/api/users/${user._id}/links`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ title: newLinkTitle, url: newLinkUrl }),
        })
            .then((res) => res.json())
            .then((updatedUser) => {
                setUser(updatedUser);
                setNewLinkTitle("");
                setNewLinkUrl("");
            })
            .catch((err) => console.error("Add Link Error:", err));
    }

    function handleDeleteLink(linkId) {
        fetch(`${baseUrl}/api/users/${user._id}/links/${linkId}`, {
            method: "DELETE",
        })
            .then((res) => res.json())
            .then((updatedUser) => setUser(updatedUser))
            .catch((err) => console.error("Delete Link Error:", err));
    }

    function handleSaveAllLinks() {
        fetch(`${baseUrl}/api/users/${user._id}/links/save`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ links: user.links }),
        })
            .then((res) => res.json())
            .then((updatedUser) => setUser(updatedUser))
            .catch((err) => console.error("Save All Links Error:", err));
    }

    function handleImageUpload(e) {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("image", file);

        fetch(`${baseUrl}/api/users/${user._id}/profile-image`, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((updatedUser) => setUser(updatedUser))
            .catch((err) => console.error("Upload Error:", err));
    }

    function handleSaveProfile() {
        if (!user) return;
        fetch(`${baseUrl}/api/users/${user._id}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: editName,
                bio: editBio,
                headline: editHeadline,
                username: editUsername,
                socials
            }),
        })
            .then((res) => res.json())
            .then((updatedUser) => {
                if (updatedUser.error) {
                    setErrorMessage(updatedUser.error);
                } else {
                    setUser(updatedUser);
                    setIsEditing(false);
                    setErrorMessage('');
                }
            })
            .catch(() => setErrorMessage("Error updating profile."));
    }

    if (errorMessage) return <div className="error">{errorMessage}</div>;
    if (!user) return <div>Loading...</div>;

    return (
        <div className={styles.container}>
            <header className={styles.profileHeader}>
                <div className={styles.profileHeaderContent}>
                    <div className={styles.imageUploadWrapper}>
                        <img
                            src={`${baseUrl}${user.profileImage}`}
                            alt={user.name}
                            className={styles.profileImage}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            className={styles.imageUploadInput}
                            title=" "
                        />
                    </div>

                    {!isEditing ? (
                        <div className={styles.centerButtons}>
                            <button className={styles.button} onClick={() => setIsEditing(true)}>Edit Profile</button>
                            <button className={styles.button} onClick={() => navigate(`/${username.replace(/^@/, "")}`)}>Back to Dashboard</button>
                        </div>
                    ) : (
                        <div className={styles.editProfile}>
                            <label>Name:
                                <input value={editName} onChange={(e) => setEditName(e.target.value)} />
                            </label>
                            <label>Headline:
                                <input maxLength={30} value={editHeadline} onChange={(e) => setEditHeadline(e.target.value)} />
                            </label>
                            <label>Bio:
                                <textarea maxLength={144} value={editBio} onChange={(e) => setEditBio(e.target.value)} />
                            </label>
                            <label>Facebook:
                                <input value={socials.facebook || ''} onChange={(e) => setSocials({ ...socials, facebook: e.target.value })} />
                            </label>
                            <label>X (Twitter):
                                <input value={socials.x || ''} onChange={(e) => setSocials({ ...socials, x: e.target.value })} />
                            </label>
                            <label>Instagram:
                                <input value={socials.instagram || ''} onChange={(e) => setSocials({ ...socials, instagram: e.target.value })} />
                            </label>
                            <label>LinkedIn:
                                <input value={socials.linkedin || ''} onChange={(e) => setSocials({ ...socials, linkedin: e.target.value })} />
                            </label>
                            <label>GitHub:
                                <input value={socials.github || ''} onChange={(e) => setSocials({ ...socials, github: e.target.value })} />
                            </label>
                            <button className={styles.button} onClick={handleSaveProfile}>Save</button>
                            <button className={styles.button} onClick={() => setIsEditing(false)}>Cancel</button>
                        </div>
                    )}
                </div>
            </header>

            <section className={styles.editLinksSection}>
                <h1>Edit Links</h1>
                {user.links?.map((link) => (
                    <div key={link._id} className={styles.linkEditItem}>
                        <a href={link.url} target="_blank" rel="noreferrer">{link.title}</a>
                        <button className={styles.button} onClick={() => handleDeleteLink(link._id)}>Delete</button>
                    </div>
                ))}
                <input
                    type="text"
                    placeholder="New Link Title"
                    value={newLinkTitle}
                    onChange={(e) => setNewLinkTitle(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="New Link URL"
                    value={newLinkUrl}
                    onChange={(e) => setNewLinkUrl(e.target.value)}
                />
                <button className={styles.button} onClick={handleAddLink}>Add Link</button>
                <button className={styles.button} onClick={handleSaveAllLinks}>Save All Links</button>
            </section>

            <aside className={styles.circleSection}>
                <h2>My Circle</h2>
                <CircleAvatars circle={user.circle} />
            </aside>
        </div>
    );
}
